import './App.css';
import { Input, Select, Layout, Menu, Row, Col, Form, Image } from 'antd';
import { Link, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState, useMemo } from 'react';
import { ListPage } from "./list";
import { GraphPage } from "./graph";
import { pageSizeOptions, pageSizeEnumMap } from './utils';

const { Header, Content, Footer } = Layout;

function App() {
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState("PublishYear");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeOptions.at(pageSizeOptions.length / 2));
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({ literatures: [], totalEntries: 0, termRelevances: [] });
  const [menu, setMenu] = useState(window.location.pathname.length ? window.location.pathname.split("/")[1] : "list");
  const [form] = Form.useForm();

  const graphData = useMemo(() => {
    if (!(filter.length && result && result.termRelevances && result.termRelevances.length)) {
      return { nodes: [], links: [] };
    }
    
    let nodes = [], links = [];
    const size = 150;
    nodes.push({ id: filter, size: size * 4, color: "#0000ff" });
    let relevances = result.termRelevances;
    relevances.splice(5);
    for (const node of relevances) {
      nodes.push({ id: node.term, size: size * (1 + Math.max(2, Math.floor(node.count / 5))) });
      links.push({ source: filter, target: node.term });
      let relatedTerms = node.relatedTerms;
      relatedTerms.splice(10);
      for (const related of relatedTerms) {
        if (nodes.findIndex((i) => i.id === related) === -1) {
          nodes.push({ id: related });
        }
        links.push({ source: node.term, target: related });
      }
    }

    return { nodes, links, focusedNodeId: filter };
  }, [result, filter]);

  useEffect(() => {
    if (!filter.length) {
      return;
    }

    setLoading(true);
    fetch(`https://api.lsa.jellykuo.com/Literature/Search?QueryText=${filter}&SearchSort=${sort}&StartRecord=${(page - 1) * pageSize}&SearchAmountPerPage=${pageSizeEnumMap[pageSize]}`, {
      method: "get"
    })
    .then((response) => response.json())
    .then((result) => { setResult({ ...result }); setLoading(false);  })
    .catch(() => {
      setResult({ literatures: [], totalEntries: 0, termRelevances: [] });
      setTimeout(() => { setLoading(false) }, 10); });
  }, [filter, page, pageSize, sort]);

  return (
    <Layout className="layout">
      <Header>
        <div className="logo"><Image src="/images/logo.png" height={64} /></div>
        <h1 className="logo">Literature Survey Assistant</h1>
        <Menu style={{ mouse: "pointer" }} theme="dark" mode="horizontal" value={menu}>
          <Menu.Item key="list">
            <Link to="/list">
              <span>List</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="graph">
            <Link to="/graph">
              <span>Graph</span>
            </Link>
          </Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: "5px 50px", height: "100%" }}>
        {false && (loading | filter.length === 0 | result.literatures.length === 0)
        ?
        <>
          <Form style={{ height: "80%" }}>
            <Row type="flex" align="middle" style={{ height: "100%", margin: "24px 0px 0px 0px"}}>
              <Col offset={6} span={12}>
                <Input.Search
                  size="large"
                  placeholder="Enter topics that you're interested in... (ex: YOLO)"
                  allowClear
                  defaultValue={filter}
                  onSearch={(v) => { setFilter(v); }}
                  loading={loading}
                  />
              </Col>
            </Row>
          </Form>
        </>
        :
        <>
          <Form form={form} style={(loading | filter.length === 0 | result.literatures.length === 0) ? { height: "100%" } : {}}>
            <Row type="flex" align={(loading | filter.length === 0 | result.literatures.length === 0) ? "middle" : null} style={(loading | filter.length === 0 | result.literatures.length === 0) ?  { height: "100%", margin: "24px 0px 0px 0px" } : { margin: "24px 0px 0px 0px"}}>
              <Col offset={6} span={12}>
                <Form.Item name="filter">
                  <Input.Search
                    size="large"
                    placeholder="Enter topics that you're interested in... (ex: YOLO)"
                    allowClear
                    defaultValue={filter}
                    onSearch={(v) => { setFilter(v); }}
                    loading={loading}
                    />
                </Form.Item>
              </Col>
              {!(loading | filter.length === 0 | result.literatures.length === 0)
              ?
              <Col offset={1}>
                <Form.Item name="sort" label="Sort By">
                  <Select value={sort} onChange={(v) => { setSort(v); }}>
                    <Select.Option value="PublishYear">Publish Year</Select.Option>
                    <Select.Option value="Relevance">Relevance</Select.Option>
                    <Select.Option value="NumberOfCitation"># of Citation</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              : null}
            </Row>
          </Form>
          <Routes>
            {/* <Route path="/" redirect /> */}
            <Route path="*" element={<Navigate to="list"/>} />
            <Route path="list" element={<ListPage loading={loading} data={result.literatures} total={result.totalEntries} page={page} pageSize={pageSize} setPage={setPage} setPageSize={setPageSize} />} />
            <Route path="graph" element={<GraphPage data={graphData} setFilter={setFilter} loading={loading} form={form} />} />
          </Routes>
        </>
        }
      </Content>
      <Footer style={{ textAlign: "center" }}>Literature Survey Assistant ©2021 Created by Software Engineering Group 18</Footer>
    </Layout>
  );
}

export default App;
