import { List, Card, Descriptions, Tag } from "antd";
import { pageSizeOptions } from "./utils";

export const ListPage = ({ loading, data, total, page, pageSize, setPage, setPageSize }) => {
  const onPaginationChange = (currentPage, currentPageSize) => {
    setPage(currentPage);
    setPageSize(currentPageSize);
  };

  return (
    <>
      <div style={{ height: "90%", width: "100%", overflowY: "auto", margin: "0px auto 0px auto", padding: "0px 5% 70px 5%" }}>
        <List
          loading={loading}
          grid={{
            gutter: 8,
            xs: 2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2,
            xxl: 2,
          }}
          dataSource={data}
          renderItem={(row) => (
            <List.Item key={row.articleNumber}>
              <Card
                title={row.title}
                extra={<a href={row.contentUrl} rel="noreferrer noopener" target="_blank">More</a>}
                style={{ height: "450px", overflowY: "clip" }}
              >
                <Descriptions column={2}>
                  <Descriptions.Item label="Year" span={2}>{row.year}</Descriptions.Item>
                  <Descriptions.Item label="Authors" span={2} contentStyle={{ overflowX: "auto" }}>{row.authors.map((author) => <Tag>{author.trim()}</Tag>)}</Descriptions.Item>
                  <Descriptions.Item label="Abstract" span={2} contentStyle={{ overflowX: "auto" }}>
                    <div style={{ textOverflow: "ellipsis" }} dangerouslySetInnerHTML={{ __html: row.abstract }} />
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </List.Item>
          )}
          pagination={(total > 0) ? {
            position: "bottom",
            showSizeChanger: true,
            onChange: onPaginationChange,
            current: page,
            pageSizeOptions: pageSizeOptions,
            pageSize: pageSize,
            total: total
          } : false}
        />
      </div>
    </>
  );
};
