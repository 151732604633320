import { Graph } from "react-d3-graph";
import { useLocation, useNavigate } from "react-router-dom";

export const GraphPage = ({ data, setFilter, form }) => {
  const myConfig = {
    nodeHighlightBehavior: true,
    node: {
      color: "lightgreen",
      size: 150,
      highlightStrokeColor: "blue",
      labelPosition: "top",
    },
    d3: {
      gravity: -500,
    },
    link: {
      highlightColor: "lightblue",
    },
    width: 1600,
    height: 1000,
    focusZoom: 2,
  };

  const navigate = useNavigate();
  const onClickNode = function(nodeId) {
    // window.alert(`Clicked node ${nodeId}`);
    setFilter(nodeId);
    form.setFieldsValue({ "filter": nodeId });
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Graph
        id="graph-id" // id is mandatory
        data={data}
        config={myConfig}
        onClickNode={onClickNode}
      />;
    </div>
  );
};
